
window._ = require('lodash');

try {
    window.$ = window.jQuery = require('jquery');
    window.Popper = require('popper.js').default;

} catch (e) {}


require('./core/bootstrap-material-design.min');
require('./plugins/perfect-scrollbar.jquery.min');
window.moment = require('moment');
require('./plugins/sweetalert2');
require('./plugins/bootstrap-tagsinput');
require('./plugins/bootstrap-notify');
require('./plugins/bootstrap-datetimepicker.min');
require('./material-dashboard');
require('./main');

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}