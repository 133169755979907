<template id="item-template">
	<div class="multiline-component">
		<div :key="`A-${deletableIndex}`" v-for="(item,deletableIndex) in deletableItems">
			<input
				type="hidden"
				:name="`deletableItems[${deletableIndex}]`"
				v-model="item.id"
			>
		</div>
		<div v-for="(row, index) in rows" :key="index" class="row">
			<div class="col-1 text-right" v-if="config && config.moreUrl">
				<a v-if="row.id" :href="`${config.moreUrl}/${row[config.moreUrlField]}`" class="btn color-blue btn-sm">
					<i :class="`fa fa-${config.moreUrlIcon ? config.moreUrlIcon : 'eye'}`"></i>
				</a>
			</div>
			<div :class="field.type != 'hidden'? inputSize: 'hidden'" v-for="(field,fieldIndex) in fields" :key="fieldIndex">
				<label v-if="field.label">{{ field.label }}</label>
				<input
					v-if="field.type=='hidden'"
					v-model="row[field.property]"
					type="hidden"
					:name="`crud[${column}][${index}][${field.property}]`"
					>
				<select
					v-if="field.type === 'select'"
					v-model="row[field.property]"
					:name="`crud[${column}][${index}][${field.property}]`"
					:required="field.required ? true : false"
					class="form-control">
					<option
						:value="key"
						v-for="(item,key) in field.listItems" :key="key">
							{{ item }}
					</option>
				</select>
				<input
					v-if="field.type === 'text'"
					v-model="row[field.property]"
					:name="`crud[${column}][${index}][${field.property}]`"
					:required="field.required ? true : false"
					:placeholder="field.placeholder"
					class="form-control"
					type="text"/>
				<textarea
					rows="3"
					v-if="field.type === 'textarea'"
					v-model="row[field.property]"
					:name="`crud[${column}][${index}][${field.property}]`"
					:required="field.required ? true : false"
					:placeholder="field.placeholder"
					class="form-control"
				>
				{{ row[field.property] }}
				</textarea>


			</div>

			<div class="col-1">
				<button type="button" v-if="rows.length > 1" class="btn just-icon btn-danger btn-sm" v-on:click="remove(index)">
					<i class="fa fa-close"></i>
				</button>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<button type="button" class="btn color-green" @click="add">
					<i class="fa fa-plus"></i> <span> elem</span>
				</button>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ['newRows','newFields','config','column'],
	template: '#item-template',
	data: function () { return {
		rows: [],
		fields: [],
		deletableItems: [],
	}},
	methods: {
		add() {
			this.rows = [...this.rows, this.createEmptyRow()];
		},
		remove(index) {
			let row = {...this.rows[index]};
			
			var property = (this.config && this.config.deleteProperty) ? this.config.deleteProperty : 'id';

			if(row[property])
				this.deletableItems.push({id: row[property]});
			
			this.rows = [...this.rows].filter((row,i) => i != index);
		},
		createEmptyRow() {
			const newRow = {};
			this.fields.forEach((field, i) => {
				if(field.type == 'select')
					newRow[field.property] = Object.keys(field.listItems)[0];
			});
			return newRow;
		}
	},
	created() {
		this.fields = this.newFields;
		this.rows = (this.newRows && this.newRows.length > 0) ? this.newRows : [this.createEmptyRow()];
		this.inputSize = this.config && this.config.size == 'full' ? 'col-12' : 'col'; 
	}
}
</script>
