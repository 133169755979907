
<template>
    <div class="form-group fileupload-group fileupload-component">
        <input 
        	type="file" 
        	v-on:change="autosubmit ? submitForm($event) : doShowPreview($event)" 
        	:name="inputName" 
        	:multiple="multiple"
        	class="form-control fileupload-input">
        <button type="button" v-on:click="openFileBrowser($event)" class="btn btn-success fileupload-btn">
            <i class="fa-plus fa" style="display: inline-block; margin-right: 3px;"></i>
            File feltöltés
        </button>
        <div v-if="previewImages.length > 0" class="fileupload-preview">
            <img
                v-for="(src,key) in previewImages" 
                :src="src"
                :key="key"
                width="100"
            />
        </div>
    </div>
</template>

<script>
export default {
	props: {
        multiple: { default: false },
        autosubmit: { default: false },
        showPreview: { default: false },
        inputName: { default: 'inputname' },
    },
    data: function () { return {
        previewImages: [],
    }},
	methods: {
		openFileBrowser(event) {
			let formGroup = $(event.target).parents('.form-group');
	        formGroup.find('.fileupload-input').trigger('click');
		},

		submitForm(event) {
			$(event.target).parents('form').submit();
		},

        doShowPreview(event) {
            if(!this.showPreview || !event.target.files)
                return;
            
            this.previewImages = [];
            self = this;
            $.each(event.target.files, function(i, file) {
              var reader  = new FileReader();
              reader.onloadend = function () {
                  self.previewImages = [...self.previewImages,reader.result];
              }

              if (file) {
                reader.readAsDataURL(file);
              }
          });
        
        }

	},
}

</script>
