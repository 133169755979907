<template>
	<button type="button" v-on:click="showConfirm($event)" class="bootbox-confirm btn-sm btn-danger btn"><i class="fa fa-trash"></i></button>
</template>

<script>
export default {
	props: ['confirmText'],
	methods: {
		showConfirm(event) {
			const self = this;
			self.$bootbox.confirm({
			    message: self.confirmText,
			    buttons: {
			        confirm: {
			            label: 'Igen',
			            className: 'btn btn-success'
			        },
			        cancel: {
			            label: 'Nem',
			            className: 'btn btn-default'
			        }
			    },
			    callback: function (result) {
				    if (result == true)
				        $(event.target).parents('form').submit();
			    }
			});
		},
	}
}

</script>
