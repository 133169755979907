<template>
     <div>
        <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
        <textarea :name="inputName" style="display:none" v-model="editorData"></textarea>
     </div>
</template>

<script>
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    export default {
        props: {
            inputName: { default: 'inputname' },
        },
        data() {
            return {
                editor: ClassicEditor,
                editorData: '<p>Rich-text editor content.</p>',
                editorConfig: {
                    removePlugins: [ 'ImageUpload' ],
                }
            };
        },
        mounted() {
            console.log(1);
        }
    }
</script>