
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
window.Vue = require('vue');

import bootbox from 'bootbox';
Object.defineProperty(Vue.prototype, '$bootbox', { value: bootbox });

import CKEditor from '@ckeditor/ckeditor5-vue';
Vue.use(CKEditor);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component("multiline-component", require("./components/Multiline.vue").default);
Vue.component("fileupload-component", require("./components/FileUpload.vue").default);
Vue.component("textarea-component", require("./components/Textarea.vue").default);
Vue.component("bootbox-component", require("./components/Bootbox.vue").default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app'
});

